@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');


.logo {
    padding: 0;
}

.title-bar {
    background: #fff;
    padding: 0.9rem;
}

.top-bar {
    background: #fff;

    ul {
        background: #fff;

        li {
            background: #fff;

            a {
                color: $primary-color;
            }
        }
    }
}

.menu-text {
    color: $primary-color;

    @media only screen and (max-width: 40em) {
        display: none !important;
    }
}

.menu-icon::after {
    background: $primary-color;
    box-shadow: 0 7px 0 $primary-color, 0 14px 0 $primary-color;
}

.dropdown.menu .submenu {
    border: none;
}

ul.menu.vertical.side>li a {
    color: $white;
    padding-left: 4px;
    font-size: 1.3em;
}

.menu.vertical>li {
    line-height: 2;
    margin-left: 10px;
}

.dropdown.menu.medium-horizontal>li.opens-left>.is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
    padding: 10px;
    border-radius: 4px;
}

.top-bar {
    z-index: 99;
}

.grid-container.full.slideshow {
    width: 100%;
}


.company {
    min-height: 100px;
}

h1 {
    color: #00768d;
    font-weight: 300;
    text-transform: uppercase;
}

.off-canvas-content {
   /* background: url(../img/x.png) no-repeat; */
    background-position: right;
    background-size: cover;
    background-attachment: fixed;
    z-index: 1 !important;
    background-position: 0 0;
    position: relative; // height: 100vh;

    &.no-bg {
        background: none;
    }
}

img {
    border-radius: 2px;
    box-shadow: 2px 3px 5px 0px rgba(158, 155, 158, 1);
}

.logo img,
.title-bar-left img {
    border-radius: none;
    box-shadow: none;
}

footer {
    background: #173963;
    color: #fff;
    padding: 24px 0 10px;

    a {
        color: rgba(255,255,255,0.6);

        &:hover {
            color: #fff;
        }
    }
}

.grid-container.full.footer {
    margin: 0;
}

.copyright {
    padding-bottom: 15px;
}

.top-bar {
    position: relative;
    border-bottom: solid 1px #f5e9db;

    ul li a {
        font-size: 18px;

        &:hover {
            color: #C3742A;
        }
    }
}

.top-bar-right .menu {
    text-transform: uppercase;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    right: 20px;
}

.content {
    margin: 2em 0;
    min-height: 500px;
}

.services {
    text-align: center;
    text-transform: uppercase;
}

.animate-fade-in {
    @include mui-animation(fade);
}

// PUSH FOOTER
//
.off-canvas-wrapper {
    overflow: visible;
}

.off-canvas-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.short-page .off-canvas-content {
    height: 100vh;
}

.main-content {
    flex: 1 0 auto;
    /* Use 1 0 auto instead of just 1 for backwards compatibility with other browsers */
}

ul.two-column {

    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

}

// Slideshow
.fullscreen-image-slider {
    .orbit-image {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        z-index: 1 !important;
        max-height: 800px;


    }

    .orbit-caption {
        background: none;
        text-align: center;
        color: $white;
        font-weight: bold;
        background: none;
        top: 10px;

        h1 {
            color: $white;
        }

    }
}

.top-bar ul li a {
    color: #636363;
    font-size: 15px;
    &:hover {
    color: #00768d;
    }
}

.button, .button.disabled, .button[disabled], .button.disabled:hover, .button[disabled]:hover, .button.disabled:focus, .button[disabled]:focus {
    color: #fff;
    background-color: #1c3860;
    &:hover {
    background-color: #f2f2f2;
    color: #317489;
    }
}
